import React, { useEffect, useState } from "react"
import { FiX } from "react-icons/fi"
import AOS from "aos"
import "aos/dist/aos.css"

import MiniEBook from "../MiniEbook/MiniEbook"
import ebooks_origin from "../../data/ebooks.json"

import "./ebooks.scss"

const EBooks = () => {
  const [ebooks, setEbooks] = useState([...ebooks_origin])
  const [filters, setFilters] = useState([])
  const [filtersElements, setFiltersElements] = useState({})

  function handleFilterClick(filter_id) {
    if (!filters.find(filter => (filter === filter_id ? filter : 0))) {
      document.getElementById(filter_id).classList.add("active")
      return setFilters([...filters, filter_id])
    }

    const indexFilter = filters.indexOf(filter_id)
    filters.splice(indexFilter, 1)
    document.getElementById(filter_id).classList.remove("active")
    return setFilters([...filters])
  }

  function hasIdThisClass(elementId, classToSearch) {
    const element = filtersElements[elementId]
    if (element) return element.classList.contains(classToSearch)
    return false
  }

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 800,
      easing: "ease-in-out",
      offset: -50,
    })
  })

  useEffect(() => {
    setFiltersElements({
      negócios: document.getElementById("negócios"),
      inovação: document.getElementById("inovação"),
      people: document.getElementById("people"),
      "ciência-de-dados": document.getElementById("ciência-de-dados"),
      "gestão-ágil": document.getElementById("gestão-ágil"),
      "transformação-digital": document.getElementById("transformação-digital"),
    })
  }, [])

  useEffect(() => {
    function compareArray(toCompare = []) {
      const result = filters.filter(filter => {
        if (toCompare.indexOf(filter) >= 0) return filter
        return 0
      })
      return result.length
    }

    const ebooks_filtered = []

    ebooks_origin.forEach(ebook => {
      if (compareArray(ebook.categories) === filters.length) {
        ebooks_filtered.push(ebook)
      }
    })

    setEbooks(ebooks_filtered)
  }, [filters])

  return (
    <div id="e-books">
      <h1 data-aos="fade-up">Materiais Ricos</h1>
      <div className="filters">
        <div data-aos="fade-up" data-aos-delay="100">
          <button
            id="negócios"
            className="btn"
            onClick={() => {
              handleFilterClick("negócios")
            }}
          >
            Negócios
            {hasIdThisClass("negócios", "active") ? <FiX size={20} /> : ""}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="100">
          <button
            id="inovação"
            className="btn"
            onClick={() => {
              handleFilterClick("inovação")
            }}
          >
            Inovação
            {hasIdThisClass("inovação", "active") ? <FiX size={20} /> : ""}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="100">
          <button
            id="people"
            className="btn"
            onClick={() => {
              handleFilterClick("people")
            }}
          >
            People
            {hasIdThisClass("people", "active") ? <FiX size={20} /> : ""}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="100">
          <button
            id="ciência-de-dados"
            className="btn"
            onClick={() => {
              handleFilterClick("ciência-de-dados")
            }}
          >
            Ciência de dados
            {hasIdThisClass("ciência-de-dados", "active") ? (
              <FiX size={20} />
            ) : (
              ""
            )}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="100">
          <button
            id="gestão-ágil"
            className="btn"
            onClick={() => {
              handleFilterClick("gestão-ágil")
            }}
          >
            Gestão ágil
            {hasIdThisClass("gestão-ágil", "active") ? <FiX size={20} /> : ""}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="100">
          <button
            id="transformação-digital"
            className="btn"
            onClick={() => {
              handleFilterClick("transformação-digital")
            }}
          >
            Transformação digital
            {hasIdThisClass("transformação-digital", "active") ? (
              <FiX size={20} />
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
      <div className="ebooks-container" style={{ marginRight: 0 }}>
        {ebooks.map((ebook, index) => (
          <div
            data-aos="fade-up"
            data-aos-delay={50 + 50 * (index % 4)}
            className="ebook-container"
            key={ebook.id}
          >
            <MiniEBook
              id={ebook.id}
              title={ebook.title}
              type={ebook.type}
              categories={ebook.categories}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default EBooks

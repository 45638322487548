import React from "react"
import Helmet from "react-helmet"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import Ebooks from "../components/EBooks/EBooks"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

const MiniEBooks = () => {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
    // document.getElementById("label").style.display = "none"
  }, [])
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Materiais Ricos | FABWORK</title>
        <link rel="canonical" href="https://fab.work/blog/" />
      </Helmet>
      <Header />
      <Ebooks />
      <Footer />
    </div>
  )
}

export default MiniEBooks

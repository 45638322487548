import React from "react"

import "./miniebook.scss"

const MiniEbook = ({ type, title, categories, id, imageType = "png" }) => {
  const image = require(`../../images/pictures/ebooks/cover/${id}.${imageType}`)

  function format(text) {
    const stringSplited = text.split("-")
    const capitalized = stringSplited.map(item => {
      // const itemCapitalized = item.charAt(0).toUpperCase() + item.slice(1)
      const newItem = item.replace("_", "-")

      return newItem
    })
    return capitalized.join(" ")
  }

  function formatCategories(categories_list = [""]) {
    const formatedCategories = categories_list.map(category => {
      const categorySplited = category.split("-")

      const lastWordIndex = categorySplited.length - 1
      const lastWord = categorySplited[lastWordIndex]
      categorySplited[lastWordIndex] =
        lastWord.charAt(0).toUpperCase() + lastWord.slice(1)

      const categoryJoined = categorySplited.join(" ")

      return categoryJoined.charAt(0).toUpperCase() + categoryJoined.slice(1)
    })

    return formatedCategories.join(" | ") //•
  }

  return (
    <div className="mini-ebook">
      <div className="image-container">
        <a href={`/material?id=${id}`}>
          <img src={image} alt={title} />
        </a>
      </div>
      <div className="text-info">
        <a href={`/material?id=${id}`}>
          <h2>{title}</h2>
        </a>
        <div className="sub-info">
          <p className="category">{formatCategories(categories)}</p>
          <p className="type">{format(type)}</p>
        </div>
      </div>
    </div>
  )
}

export default MiniEbook
